
import { defineComponent, onMounted, ref } from "vue";
import { createClient } from "contentful";
import { marked } from 'marked';
import { ContentfulPage } from "./types/contentful-page.interface";
import { ContentfulCompanyInfo } from "./types/contentful-company-page.interface";

export default defineComponent({
  name: "App",
  setup() {
    document.title = "Wealthtime - We've moved home!";
    let header = ref<string>("We’ve moved home!");
    let content = ref<string>("We’ve rebranded from Novia to Wealthtime and we now live over [here](http://www.wealthtime.com). If you have any Novia bookmarks please update them so that you can enter the new site as easily as before.");
    let copyright = ref<string>("© 2023 Wealthtime");
    let fcaRegistrationInformation = ref<string>("Wealthtime is a trading name of Novia Financial PLC. Novia Financial Plc is a private limited company registered in England & Wales. No. 06467886. Registered office: Cambridge House, Henry St, Bath, BA1 1JS. Novia Financial Plc is authorised and regulated by the Financial Conduct Authority. FCA Number 481600.");
    let phoneCharges = ref<string>("Phone lines are open 9am to 5pm Monday to Friday excluding bank holidays.");
    let phone = ref<string>("0345 680 8000");
    let email = ref<string>("info@wealthtime.com");
    let logo = ref(require(<string>'./assets/wealthtime_neg.svg'));

    onMounted(async () => {
      const client = createClient({
        space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
        environment: process.env.VUE_APP_CONTENTFUL_ENVIRONMENT,
        resolveLinks: true,
      });
    
      try{
        const companyPageData = (await client.getEntry<ContentfulCompanyInfo>(`${process.env.VUE_APP_CONTENTFUL_COMPANYINFO_PAGE_ID}`, {include: 5}));
        const redirectPageData = (await client.getEntry<ContentfulPage>(`${process.env.VUE_APP_CONTENTFUL_REDIRECT_PAGE_ID}`, {include: 5}));
     
        document.title = `${companyPageData.fields.companyName} - ${redirectPageData.fields.pageTitle}`;
        copyright.value = companyPageData.fields.copyright;
        fcaRegistrationInformation.value = companyPageData.fields.fcaRegistrationInformation;
        phoneCharges.value = companyPageData.fields.phoneCharges;
        phone.value = companyPageData.fields.primaryPhoneNumber;
        email.value = companyPageData.fields.primaryEmailAddress;
        logo.value = companyPageData.fields.companyLogo.fields.file.url;

        redirectPageData.fields.labels.forEach(function (label) {        
          switch (label.fields.name)
          {
            case "BookmarksPlaceholder":
              header.value = label.fields.content.fields.term;
              break;
          }
        });

        redirectPageData.fields.helpTexts.forEach(function (helpText) {        
          switch (helpText.fields.name)
          {
            case "Bookmarks-wehavemoved": 
              content.value = helpText.fields.content.fields.term;
              break;
          }
        });
      }
      catch (error){
          console.log(error);
      }
    });

    content.value = marked(content.value);

    return { header, content, copyright, fcaRegistrationInformation, phoneCharges, phone, email, logo };
  },
});
